import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Groups2Icon from '@mui/icons-material/Groups2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import axios from 'axios';
import { getCookie } from '../utils/cookies';
import { getopcinesMenu } from '../services/apis'
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import { Fichar } from '../global/fichaje/Fichar';
import ConstructionIcon from '@mui/icons-material/Construction';
//mapa de iconos
const iconMapping = {
    TodayIcon: <TodayIcon />,
    FolderSpecialIcon: <FolderSpecialIcon />,
    Groups2Icon: <Groups2Icon />,
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
    QuickreplyIcon: <QuickreplyIcon />,
    Fichar: <Fichar />,
    ConstructionIcon: <ConstructionIcon/>
};

export const MainMenu = () => {
    // Global
    const navigate = useNavigate();

    // Estados auxiliares
    const [loaded, setLoaded] = useState(false);

    // Estados útiles
    const [opciones, setOpciones] = useState([]);

    // Cargar opciones menú principal
    const cargarOpciones = async () => {
        try {
            const response = await getopcinesMenu();
            console.log("aa", response.data)
            setOpciones(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <div className='menu-principal fade-in-2'>
            {!loaded && (<CircularProgress />)}
            {loaded && (
                <Grid xs={12} container spacing={3} justifyContent="center">
                    {/* <Fichar></Fichar> */}
                    {opciones.map((opcion) => (<>
                        <Grid item xs={12} sm={6} md={4} lg={3} key={opcion.id}>

                            {opcion.tipo == 1 && (
                                iconMapping[opcion.icon]
                            )}
                            {
                                opcion.tipo == 0 && (
                                    <Card
                                        onClick={() => navigate(opcion.url)}
                                        className="menu-card"
                                        style={{ cursor: 'pointer', transition: '0.3s', borderRadius: 15, boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.4)', background: "var(--fondoTarjeta)" }}
                                    >
                                        <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                                            <IconButton color='primary'>
                                                {iconMapping[opcion.icon]}
                                            </IconButton>
                                            <Typography variant="h6" component="div">
                                                {opcion.nombre.toUpperCase()}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                        </Grid>
                    </>
                    ))}
                </Grid>
            )}
        </div>
    );
};
