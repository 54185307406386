import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react'

export const Pasos = ({steps, pasoActivo}) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={pasoActivo} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
