import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import HomeIcon from '@mui/icons-material/Home';
import { Button, Grid } from '@mui/material';

export const SubHeader = () => {
    const Navigate = useNavigate();


    const goBack = () => {
        window.history.back();
    }

    return (
        <Grid container xs={12} justifyContent={'space-between'}>
            <Button className='returnButton' variant="contained" color="primary"  onClick={() => goBack()}> <ArrowCircleLeftIcon 
            // sx={{ padding: 0.3, fontSize: 30}} 
            fontSize="small" /> &nbsp;Atras &nbsp;</Button>
            <Button className='returnButton' variant="contained" color="primary"  onClick={() => Navigate("/")}> <HomeIcon 
            // sx={{ padding: 0.3, fontSize: 30}} 
            fontSize="small" /> &nbsp;Inicio &nbsp;</Button>
        </Grid>
    )
}
