import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { getCookie } from '../utils/cookies';

const PrivateRoute = () => {
    const [isTokenValid, setIsTokenValid] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const verifyToken = async () => {
            const token = await getCookie('PHPSESSID');
            if (token) {
                try {
                    const response = await axios.post('https://www.jcinformatics.com/tessamanagment/WS/VerificarToken.php', {
                        token: token,
                    });

                    if (response.data.success) {
                        setIsTokenValid(true);
                        console.log("Token verified successfully");
                    } else {
                        setIsTokenValid(false);
                        localStorage.clear();
                        console.log("Token verification failed");
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    setIsTokenValid(false);
                    localStorage.clear();
                }
            } else {
                setIsTokenValid(false);
                localStorage.clear();
            }
        };

        verifyToken();
    }, [location]);

    if (isTokenValid === null) {
        return <div><CircularProgress /></div>;
    }

    return isTokenValid ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
