import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import TaskIcon from '@mui/icons-material/Task';
import FeedIcon from '@mui/icons-material/Feed'
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
export const OpcionesMenuUrlId = ({ opciones }) => {
    const iconMapping = {
        HomeRepairServiceIcon: <HomeRepairServiceIcon />,
        TaskIcon: <TaskIcon />,
        FeedIcon: <FeedIcon />,
        ConstructionIcon: <ConstructionIcon />,
        PersonIcon: <PersonIcon />,
        AccountTreeIcon: <AccountTreeIcon />,
        CircleNotificationsIcon: <CircleNotificationsIcon />
    };
    const navigate = useNavigate();
    const param = useParams();
    return (
        <div className='menu-principal fade-in-2'>
            <Grid container spacing={3} justifyContent="center">
                {opciones.map(opcion => (
                    
                    <Grid item xs={12} sm={6} md={4} lg={3} key={opcion.id}>
                        {    console.log(opcion.url + "/" + param.id)
                        }
                        <Card
                            onClick={() => navigate(opcion.url + "/" + param.id)}
                            className="menu-card"
                            style={{ cursor: 'pointer', transition: '0.3s', borderRadius: 15, boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.4)', background: "var(--fondoTarjeta)" }}
                        >
                            <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                                <IconButton color='primary'>
                                    {iconMapping[opcion.icon]}
                                </IconButton>
                                <Typography variant="h6" component="div">
                                    {opcion.nombre.toUpperCase()}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                ))}
            </Grid>
        </div>)
}
