import React, { useEffect } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { getPrueba } from '../../services/apis';

export const Calendario = () => {
  const Navigate = useNavigate();

  const cargarTareas = async () => {
    try {
        const response = await getPrueba();

    } catch (error) {
        console.error("Error al añadir el cliente:", error);
    }
}
useEffect(() => {
    cargarTareas();
}, [])


  return (
    <div>
      <h1>CALENDARIO</h1>
      <div className="felx-left">
        <Button
          variant='contained'
          size='large'
          className='menu-button'
          onClick={() => Navigate("/")}
        >
          <KeyboardReturnIcon /> &nbsp;  Volver
        </Button>
      </div>
    </div>

  )
}
