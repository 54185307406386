import React, { useEffect, useState } from 'react'
import { getTodosFichajes } from '../../services/apis';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';
import { FichajeCard } from './FichajeCard';
import { CircularProgress, Grid } from '@mui/material';

export const Fichajes = () => {

    const [fichajes, setFichajes] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const obtenerFechaActual = () => {
        const now = new Date(); // Obtiene la fecha y hora actual
        const year = now.getFullYear(); // Obtiene el año
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Obtiene el mes (añade 1 porque los meses van de 0 a 11)
        const day = String(now.getDate()).padStart(2, '0'); // Obtiene el día del mes
        const hours = String(now.getHours()).padStart(2, '0'); // Obtiene las horas
        const minutes = String(now.getMinutes()).padStart(2, '0'); // Obtiene los minutos
        const seconds = String(now.getSeconds()).padStart(2, '0'); // Obtiene los segundos

        // Devuelve la fecha en el formato "YYYY-MM-DD HH:MM:SS"
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const obtenersoloFechaActual = () => {
        const now = new Date(); // Obtiene la fecha y hora actual
        const year = now.getFullYear(); // Obtiene el año
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Obtiene el mes (añade 1 porque los meses van de 0 a 11)
        const day = String(now.getDate()).padStart(2, '0'); // Obtiene el día del mes

        // Devuelve la fecha en el formato "YYYY-MM-DD HH:MM:SS"
        return `${year}-${month}-${day}`;
    };

    const consultarFichaje = async () => {
        const fechaActual = obtenersoloFechaActual(); // Obtiene la fecha actual
        try {
            const response = await getTodosFichajes(fechaActual);
            setFichajes(response.data);
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }

    useEffect(() => {
        consultarFichaje();
    }, [])
    return (
        <>
            <SubHeader />

            <div className='menu-principal fade-in-2'>
                {!loaded && (<CircularProgress />)}
                {loaded && (
                    <Grid xs={12} container spacing={3} justifyContent="center">
                        {fichajes.length >= 1 && (
                            <Grid container spacing={3} justifyContent="center">
                                {fichajes.map((fichaje, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        {/* Pasar los datos de fichaje como props al componente FichajeCard */}
                                        <FichajeCard fichaje={fichaje} index={index} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
        </>
    );
}

