import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Button } from '@mui/material';


export const Taller = () => {
    const Navigate = useNavigate();
    const param = useParams();


    return (
        <div>
            <h1>TALLER</h1>

        </div>

    )
}
