import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, InputBase, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { getTarea, postComentarioTarea } from '../../../services/apis';
import { useParams } from 'react-router-dom';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SendIcon from '@mui/icons-material/Send';


export const TicketDialog = ({ open, setOpen, ticketInfo, handleEvent, backgroundCard, textColor }) => {
    const handleClose = () => {
        setOpen(false);
    };

    //aux
    const mensajePorDefecto = [{
        autor: "3",
        color: "7303fc",
        comentario: "Escribe un Comentario",
    }];
    //utils
    const [comentarios, setComentarios] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [opciones, setOpciones] = useState([]);


    const param = useParams();

    const handleInputChange = (event) => {
        setMensaje(event.target.value);
    };




    useEffect(() => {
        if (open === true) {
        }
    }, [open])



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor,
            }}>
                <Grid container xs={12} direction="row" justifyContent="center" alignItems="center" >
                    <div className='flex-rigth'>
                        <CancelIcon variant='contained' onClick={handleClose}></CancelIcon>
                    </div>
                    <DialogTitle id="alert-dialog-title">
                        <strong>{ticketInfo.descripcion}</strong>
                    </DialogTitle>
                    <Grid container xs={8} >
                        <img src={ticketInfo.imagen} alt="Imagen seleccionada" className='foto-ticket' />
                    </Grid>
                    <div id="alert-dialog-description">
                        <strong> Descripción:</strong> {ticketInfo.descripcion}
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                {opciones.map((opcion, index) => (
                    <Grid container xs={3} sm={3} md={3} xl={3} gap={1} justifyContent="space-around" alignItems="center">
                        <Button
                            variant='contained'
                            className='button-default'
                            key={index}
                            onClick={() => handleEvent(opcion.id_estado)}
                            disabled={
                                opcion.id_estado == 4 ? ticketInfo.id_estado != 3 :
                                    ticketInfo.id_estado == 3 && opcion.id_estado != 4
                                        ? true
                                        : (ticketInfo.id_estado == 1)
                                            ? (opcion.id_estado == 1)
                                            : (opcion.id_estado == 2)}
                        >
                            {opcion.nombre}
                        </Button>
                    </Grid>

                ))}
            </DialogActions>
        </Dialog>
    )
}
