import {
    Alert, Autocomplete, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Grid, IconButton, InputAdornment,
    InputBase, LinearProgress, MenuItem, Paper, Select, Snackbar, TextField
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import { postTarea } from '../../../services/apis';
import AutocompleteList from '../../../utils/componentes-utiles/AutocompleteList';
import { AlertError } from '../../../utils/componentes-utiles/AlertError';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoopIcon from '@mui/icons-material/Loop';
import useTicketReader from '../../../utils/componentes-utiles/useTicketReader';
import LinearProgressWithLabel from '../../../utils/componentes-utiles/LinearProgressWithLabel';
import { Pasos } from '../../../utils/componentes-utiles/Pasos';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const CrearTicket = ({ open, setOpen, backgroundCard, textColor }) => {
    const params = useParams()

    const [camara, setCamara] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [paso, setPaso] = useState(0);
    const steps = ['', '', '', '', ''];
    const [monedas, setMonedas] = useState([
        { name: "EUR", symbol: "€", idMoneda: "1" },
        { name: "USD", symbol: "$", idMoneda: "2" },
        { name: "GBP", symbol: "£", idMoneda: "3" },
        { name: "PLN", symbol: "zł", idMoneda: "4" },
    ]);
    const handleClose = () => {
        setOpen(false);
    };

    const { status, total, progress } = useTicketReader(imagenSeleccionada);

    const param = useParams();
    const [errorAlert, setErrorAlert] = useState(false);
    const [clienteSelec, setClienteSelec] = useState({ label: "Seleccione un Cliente", value: "0" });
    const [trabajadorSelec, setTrabajadorSelec] = useState([]);
    const [tarea, setTarea] = useState({
        'fecha': new Date(),
        'asunto': '',
        'descripcion': '',
        'localizacion': '',
        'id_cliente': null,
        'minutos': '',
        'id_proyecto': param.id
    });

    useEffect(() => {
        const tareaNueva = { ...tarea, importe: total };
        setTicket(tareaNueva);
        console.log(tareaNueva)
    }, [total])

    const cargarCrearTicket = async () => {

    }
    const handleChange = (event) => {
        const tareaNueva = { ...tarea, [event.target.name]: event.target.value };
        setTicket(tareaNueva);
        setErrorAlert(false)
    };

    const isNullInObject = (obj) => {
        // Lista de campos que se deben omitir en la validación
        const camposExcepcion = ['localizacion', 'minutos', 'id_cliente', 'id_proyecto'];
        return Object.keys(obj).every(key => {
            if (camposExcepcion.includes(key)) {
                return true;
            }
            return obj[key] !== null && obj[key] !== '' && obj[key] != 0;
        });
    };

    const crearTarea = async () => {
        const nuevaTareaTmp = { ...tarea };
        nuevaTareaTmp.id_cliente = clienteSelec.value;
        nuevaTareaTmp.trabajadores = trabajadorSelec;

        const tareaComprobada = isNullInObject(nuevaTareaTmp);
        console.log(tareaComprobada);

        if (!tareaComprobada) {
            console.error("Hay campos vacíos o no válidos en la tarea");
            setErrorAlert(true);
            console.log(tarea);
            return; // Detener la ejecución si hay campos no válidos
        }

        try {
            const response = await postTarea(nuevaTareaTmp);
            if (response.success === true) {
                handleClose();

                console.log(nuevaTareaTmp);
                // cargarTareas();
                setTarea({
                    'titulo': '',
                    'fecha': new Date(),
                    'asunto': '',
                    'descripcion': '',
                    'localizacion': '',
                    'id_cliente': null,
                    'minutos': '',
                    'id_proyecto': param.id
                });
                setClienteSelec({ label: "Seleccione un Cliente", value: "0" });
                setTrabajadorSelec([]);
                handleClose();

            }
        } catch (error) {
            console.error("Error al cargar tareas", error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        crearTarea();
    };

    //funciones viejas//////////////////////////////
    const obtenerFechaActual = () => {
        const fechaActual = new Date();
        return fechaActual.toISOString(); // Retorna la fecha y hora actual en formato ISO string
    };
    const [ticket, setTicket] = useState({
        idProyecto: params.id,
        descripcion: null,
        fecha: obtenerFechaActual(),
        importe: null,
        moneda: 1,
        usuario: "admin"
    });

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        setPaso(1);
        reader.onloadend = () => {
            setImagenSeleccionada(reader.result);
            setCamara(true);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const fileInputRef = useRef(null);

    const handleLoopIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
            fullWidth
            maxWidth={'md'}

            scroll='body'

        >

            <DialogContent style={{
                backgroundColor: `#${backgroundCard}`,
                color: textColor
            }}>

                <Grid container direction="row" alignItems={"center"} justifyContent={"space-between"}>

                    <div id="form-dialog-title">
                        <h3>Subir Ticket</h3>
                    </div>
                    <div className='flex-right'>
                        <CancelIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                </Grid>
                <Grid container xs={12} direction={'row'} alignItems={'center'}>

                    <Grid container xs={1} >
                        <Button onClick={() => setPaso(paso - 1)} color="primary" disabled={paso === 0 ? true : false}>
                            <NavigateBeforeIcon ></NavigateBeforeIcon>
                        </Button>
                    </Grid>
                    <Grid container xs={10} >
                        <Pasos steps={steps} pasoActivo={paso}></Pasos>
                    </Grid>
                    <Grid container xs={1} >
                        <Button onClick={() => setPaso(paso + 1)} disabled={paso === 0 ? !imagenSeleccionada : paso === 1 ? !ticket.importe || !ticket.moneda : paso === 2 ? !ticket.importe || !ticket.moneda : false }color="primary">
                            <NavigateNextIcon ></NavigateNextIcon>
                        </Button>
                    </Grid>

                </Grid>

                <form onSubmit={handleSubmit}>
                    {paso === 2 && (
                        <Grid container spacing={2} direction="column">



                            <Grid item>
                                <TextField
                                    label="Asunto"
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    name="asunto"
                                    value={tarea.asunto}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Descripción"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    name="descripcion"
                                    value={tarea.descripcion}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                        </Grid>
                    )}

                    <div className='tickets-container'>

                        {paso === 0 && (
                            <div>
                                {/* {!camara && !imagenSeleccionada && ( */}
                                <>
                                    <h3>Haz una foto o subela</h3>
                                    <Grid container xs={12} justifyContent={'center'}>
                                        <Button variant="contained" onClick={handleLoopIconClick}>
                                            <CameraAltIcon className='flex-center' />
                                        </Button>
                                    </Grid>
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        style={{ display: 'none' }}
                                        accept='image/*'
                                        onChange={handleFileInputChange}
                                    />
                                </>
                                {/* )} */}


                            </div>
                        )}
                        {paso === 1 && (

                            <div>
                                {/* {(imagenSeleccionada && camara) && ( */}
                                <Grid container direction="column" alignItems={"center"} justifyContent={"center"} padding={"1em"}>
                                    <Grid container xs={8} direction={'row'} justifyContent={'center'} >
                                        <img src={imagenSeleccionada} alt="Imagen seleccionada" className='confirmar-imagen' />
                                    </Grid>
                                    <Grid container xs={8} padding={"1em"} direction={'row'} justifyContent={'center'} >
                                        <LinearProgressWithLabel progress={progress} />
                                    </Grid>
                                    <Grid container xs={12} direction={'row'} justifyContent={'center'}>
                                        <div className='ticket-element'>
                                            <TextField id="filled-basic" label="importe" name="importe" onChange={handleChange} value={ticket.importe} variant="filled" type='number' className='textfield' />
                                        </div>

                                        <div className='ticket-element'>
                                            <Select
                                                labelId="label"
                                                id="select"
                                                name='moneda'
                                                value={ticket.moneda}
                                                onChange={handleChange}
                                            >
                                                {monedas.map((moneda) => (
                                                    <MenuItem key={moneda.id} value={moneda.idMoneda}>{moneda.symbol}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid container xs={12} direction="row" alignItems={"center"} padding={"1em"} justifyContent={"center"}>
                                        <ButtonGroup disabled={!ticket.importe || !total} variant="contained" aria-label="Basic button group">
                                            <Button type={'filled'} onClick={() => {
                                                setCamara(false);
                                                setPaso(2);
                                            }}>
                                                <CheckIcon className='flex-center' />
                                            </Button>
                                            <Button className='button-aux' onClick={handleLoopIconClick}>
                                                <LoopIcon className='flex-center' />
                                            </Button>
                                            <div>
                                                <input
                                                    ref={fileInputRef}
                                                    type='file'
                                                    style={{ display: 'none' }}
                                                    accept='image/*'
                                                    onChange={handleFileInputChange}
                                                />
                                            </div>
                                            <Button className='button-aux' onClick={() => { setImagenSeleccionada(null); setCamara(false); }}>
                                                <DeleteForeverIcon className='flex-center' />
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                {/* )} */}
                            </div>
                        )}
                    </div>
                </form>
            </DialogContent >
            <DialogActions style={{
                backgroundColor: `#${backgroundCard}`,
                color: textColor
            }}>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>




                <Button onClick={handleSubmit} disabled={paso !== 4} color="primary" variant="contained">
                    Subir Ticket
                </Button>

            </DialogActions>
            {
                errorAlert && (
                    <AlertError open={errorAlert} setOpen={setErrorAlert} titulo={"Error"} mensaje={"Falta rellenar algún campo obligatorio"} />
                )
            }
        </Dialog >
    );
};
