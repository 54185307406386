import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React from 'react';

export const AlertError = ({ open, setOpen, titulo, mensaje }) => {
    return (
        <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={4000} // Se cierra automáticamente después de 4 segundos
        >
            <Alert severity="error" variant="filled">
                <AlertTitle>{titulo}</AlertTitle>
                {mensaje}
            </Alert>
        </Snackbar>
    );
};
