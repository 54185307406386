import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, InputBase, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { getTarea, postComentarioTarea } from '../../../services/apis';
import { useParams } from 'react-router-dom';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SendIcon from '@mui/icons-material/Send';

export const TareaDialog = ({ open, setOpen, tareaInfo, handleEvent, backgroundCard, textColor }) => {
    const handleClose = () => {
        setOpen(false);
    };

    //aux
    const mensajePorDefecto = [{
        autor: "3",
        color: "7303fc",
        comentario: "Escribe un Comentario",
    }];
    //utils
    const [comentarios, setComentarios] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [opciones, setOpciones] = useState([]);


    const param = useParams();

    const handleInputChange = (event) => {
        setMensaje(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            enviarComentario();
        }
    };


    const cargarTareas = async () => {
        try {
            const response = await getTarea(param.id, tareaInfo.id, 2);
            setComentarios(response.data.comentarios.length > 0 ? response.data.comentarios : mensajePorDefecto);
            setOpciones(response.data.opcionesTareas);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    const enviarComentario = async () => {

        if (mensaje != '') {
            let temp = [...comentarios];
            let nuevoComentario = {
                autor: "1",
                color: '#FFFFFFF',
                comentario: mensaje,
                fecha: "2024-06-10",
                nombre: localStorage.username,
                id_tarea: tareaInfo.id,
                id_proyecto: tareaInfo.id_proyecto
            }
            try {
                const response = await postComentarioTarea(nuevoComentario);


            } catch (error) {
                console.error("Error al añadir el cliente:", error);
            }

            temp.push(nuevoComentario);
            setMensaje('')
            cargarTareas()
        }

    }

    useEffect(() => {
        if (open === true) {
            cargarTareas();
        }
    }, [open])



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            
            <DialogContent style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                <div className='flex-rigth'>
                    <CancelIcon variant='contained' onClick={handleClose}></CancelIcon>
                </div>
                <DialogTitle id="alert-dialog-title">
                    {tareaInfo.asunto}
                </DialogTitle>
                <div id="alert-dialog-description">
                    <strong> Cliente: </strong> {tareaInfo.cliente}
                </div>
                <div id="alert-dialog-description">
                    <strong> Descripción:</strong> {tareaInfo.descripcion}
                </div>
                {comentarios.length > 0 && (
                    <>
                        <div className='envase-chat'>
                            <strong>&nbsp;Comentarios:</strong>
                            <div className='contenedor-chat'>
                                {comentarios.map((comentario => (
                                    <div
                                        className={`bocadillo-chat ${comentario.autor == 1 ? 'autor' : ''}`}
                                        key={comentario.id}
                                    >                                        <div style={{ color:  comentario.color }}>
                                            <strong>{comentario.nombre}</strong>
                                        </div>
                                        <div>
                                            {comentario.comentario}
                                        </div>
                                        <div className='comentario-fecha'>
                                            {comentario.fecha}
                                        </div>
                                    </div>
                                )))}

                            </div>
                            <Grid xs={12} className='chat-bar'>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', backgroundColor: '#e1e0e0' }}
                                    variant='outlined'
                                    onSubmit={(e) => { e.preventDefault(); enviarComentario(); }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Escribe un mensaje nuevo"
                                        inputProps={{ 'aria-label': 'Escribe un mensaje nuevo' }}
                                        value={mensaje}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="send" >
                                        <SendIcon onClick={() => enviarComentario()} />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </div>

                    </>
                )}

            </DialogContent>
            <DialogActions style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                {opciones.map((opcion, index) => (
                    <Grid container xs={3} sm={3} md={3} xl={3} gap={1} justifyContent="space-around" alignItems="center">
                        <Button
                            variant='contained'
                            className='button-default'
                            key={index}
                            onClick={() => handleEvent(opcion.id_estado)}
                            disabled={
                                opcion.id_estado == 4 ? tareaInfo.id_estado != 3 :
                                    tareaInfo.id_estado == 3 && opcion.id_estado != 4
                                        ? true
                                        : (tareaInfo.id_estado == 1)
                                            ? (opcion.id_estado == 1)
                                            : (opcion.id_estado == 2)}
                        >
                            {opcion.nombre}
                        </Button>
                    </Grid>

                ))}
            </DialogActions>
        </Dialog>
    )
}
