import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { postSA } from '../../../services/apis';
import { useParams } from 'react-router-dom';
import { AlertError } from '../../../utils/componentes-utiles/AlertError';

export const FormCrearSA = ({ open, setOpen, tareaInfo, handleEvent, backgroundCard, textColor, opclientes, cargarTareas, opTrabajadores }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const param = useParams();
    const [errorAlert, setErrorAlert] = useState(false);

    const [trabajadorSelec, setTrabajadorSelec] = useState({ label: "Aginar a un Trabajador", value: "0" });

    // Estados locales para los campos del formulario
    const [tarea, setTarea] = useState({
        'fecha': new Date(),
        'asunto': null,
        'descripcion': null,
        'localizacion': null,
        'mail': localStorage.getItem('mail'),
        'minutos': null,
        'id_proyecto': param.id
    });

    const handleChange = (event) => {
        const tareaNueva = { ...tarea, [event.target.name]: event.target.value };
        setTarea(tareaNueva);
        setErrorAlert(false);
    };

    const isNullInObject = (obj) => {
        const camposExcepcion = ['localizacion', 'minutos', 'id_cliente', 'id_proyecto'];
        return Object.keys(obj).every(key => {
            if (camposExcepcion.includes(key)) {
                return true;
            }
            return obj[key] !== null && obj[key] !== '' && obj[key] != 0;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const tareaComprobada = isNullInObject(tarea);

        if (!tareaComprobada) {
            console.error("Hay campos vacíos o no válidos en la tarea");
            setErrorAlert(true);
            console.log(tarea);
            return; // Detener la ejecución si hay campos no válidos
        }

        crearTarea();
    };

    const crearTarea = async () => {
        const nuevaTareaTmp = { ...tarea };
        nuevaTareaTmp.trabajadores = trabajadorSelec;

        try {
            const response = await postSA(nuevaTareaTmp);
            if (response.success === true) {
                cargarTareas();
                setTarea({
                    'titulo': null,
                    'fecha': new Date(),
                    'asunto': null,
                    'descripcion': null,
                    'localizacion': null,
                    'mail': localStorage.getItem('mail'),
                    'minutos': null,
                    'id_proyecto': param.id
                });
                setTrabajadorSelec({ label: "Aginar a un Trabajador", value: "0" });
                handleClose();
            }
        } catch (error) {
            console.error("Error cargar tareas", error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                Nueva Solicitud de Ayuda
            </DialogTitle>

            <DialogContent style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                <div className='flex-right'>
                    <CancelIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <TextField
                                label="Mail de respuesta"
                                variant="outlined"
                                multiline
                                fullWidth
                                name="mail"
                                value={tarea.mail}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Asunto"
                                variant="outlined"
                                multiline
                                fullWidth
                                name="asunto"
                                value={tarea.asunto}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Descripción"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                name="descripcion"
                                value={tarea.descripcion}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Enviar SA
                </Button>
            </DialogActions>
            {errorAlert && (
                <AlertError open={errorAlert} setOpen={setErrorAlert} titulo={"Error"} mensaje={"Falta rellenar algún campo obligatorio"} />
            )}
        </Dialog>
    );
};
