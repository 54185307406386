import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, InputBase, Paper, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SendIcon from '@mui/icons-material/Send';
import { useParams } from 'react-router-dom';
import { actualizarTarea } from '../../../services/apis';
import AutocompleteList from '../../../utils/componentes-utiles/AutocompleteList';

export const FormEditarTarea = ({ open, setOpen, tareaInfo, handleEvent, backgroundCard, textColor, opclientes, cargarTareas, opTrabajadores, trabajadores }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const param = useParams();


    const [clienteSelec, setClienteSelec] = useState({ label: "Seleccione un Cliente", value: "0" });
    const [trabajadorSelec, setTrabajadorSelec] = useState({ label: "Aginar a un Trabajador", value: "0" });

    // Estados locales para los campos del formulario

    const [tarea, setTarea] = useState(tareaInfo)

    const handleChange = (event) => {
        if (event.target.name === "minutos") {
            let value = parseInt(event.target.value, 10);

            if (value >= 0 && value <= 59) {
                const tareaNueva = { ...tarea, [event.target.name]: value };
                setTarea(tareaNueva);
            } else {
                // Si el valor es inválido, ajustamos a 59 o 0.
                value = value > 59 ? 59 : 0;
                const tareaNueva = { ...tarea, [event.target.name]: value };
                setTarea(tareaNueva);
            }
        } else if (event.target.name === "horas") {
            let value = parseInt(event.target.value, 10);

            if (value >= 0) {
                // No hay límite máximo para horas
                const tareaNueva = { ...tarea, [event.target.name]: value };
                setTarea(tareaNueva);
            } else {
                // Aseguramos que no sea negativo, ajustando a 0 si lo es.
                value = 0;
                const tareaNueva = { ...tarea, [event.target.name]: value };
                setTarea(tareaNueva);
            }
        }
        else {
            const tareaNueva = { ...tarea, [event.target.name]: event.target.value };
            setTarea(tareaNueva);
        }
    };

    useEffect(() => {
        let clienteSeleccionado = opclientes.filter(opcion => tareaInfo.id_cliente == opcion.value);
        setClienteSelec(clienteSeleccionado[0]);
    }, [])


    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí se puede manejar el envío de los datos (ejemplo: llamada a una API o validación)
        // Cierra el formulario
        editarTarea();
        handleClose();
    };

    const editarTarea = async () => {
        const nuevaTareaTmp = { ...tarea };
        nuevaTareaTmp.id_cliente = clienteSelec.value;
        nuevaTareaTmp.trabajadores = trabajadorSelec;
        try {
            const response = await actualizarTarea(nuevaTareaTmp);
            console.log(response)
            if (response.success == true) {
                cargarTareas();
                setTarea({
                    'fecha': new Date(),
                    'asunto': null,
                    'descripcion': null,
                    'localizacion': null,
                    'id_cliente': null,
                    'minutos': null,
                    'horas': null,
                    'id_proyecto': param.id
                })
                setClienteSelec({ label: "Seleccione un Cliente", value: "0" })
            }


        } catch (error) {
            console.error("Error cargar tareas", error)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                Editar Tarea
            </DialogTitle>

            <DialogContent style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                <div className='flex-right'>
                    <CancelIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Autocomplete
                                className={opclientes ? "inputDateInput" : 'inputDateInput-empty'}
                                disablePortal
                                id="combo-box-demo"
                                name="Variedad"
                                options={opclientes}
                                sx={{ width: 300 }}
                                value={clienteSelec}
                                onChange={(event, newValue) => {
                                    setClienteSelec(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="Cliente*" />}
                            />
                        </Grid>
                        <Grid item>
                           Asignar:
                            <AutocompleteList array={opTrabajadores} arrayPre={trabajadores} setArray={setTrabajadorSelec}></AutocompleteList>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Asunto"
                                variant="outlined"
                                multiline
                                fullWidth
                                name="asunto"
                                value={tarea.asunto}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Descripción"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                name="descripcion"
                                value={tarea.descripcion}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item container spacing={2} direction="row">
                            <Grid item>
                                <TextField
                                    label="Horas"
                                    type="number"
                                    variant="outlined"
                                    name="horas"
                                    value={tarea.horas}
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Minutos"
                                    type="number"
                                    variant="outlined"
                                    name="minutos"
                                    value={tarea.minutos}
                                    onChange={handleChange}
                                    InputProps={{ inputProps: { min: 0, max: 59 } }}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Editar Tarea
                </Button>
            </DialogActions>
        </Dialog>
    );
};