import React from 'react';
import { LinearProgress, Box, Grid, TextField, Select, MenuItem, Typography } from '@mui/material';

const LinearProgressWithLabel = ({ progress }) => {
    return (
        <Box className='barra-carga' >
            {progress}%

            <Typography
                variant="body2"
                color="white"
                textAlign="center"
                fontWeight="bold"
                gutterBottom
            >

            </Typography>
                <LinearProgress  variant="determinate" value={progress} />
        </Box>
    );
};
export default LinearProgressWithLabel;