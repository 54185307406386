import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, ButtonGroup, Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { isColorDark, obtenerFechaHoraSQL } from '../../../utils/utils';
import { actualizarTarea, eliminarTarea, updateTarea } from '../../../services/apis';
import InfoIcon from '@mui/icons-material/Info';
import { TareaDialog } from './TareaDialog';
import { FormEditarTarea } from './FormEditarTareay';



export const TareaCard = ({ tarea, opciones, estados, cargarTareas, opclientes, opTrabajadores }) => {

    const [tareaInfo, setTareaInfo] = useState(tarea);
    const [tareaEditar, setTareaEditar] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [backgroundCard, setBackgroundCArd] = useState(estados.find(estado => estado.id === tarea.id_estado).color);
    const [textColor, setTextColor] = useState(isColorDark(tareaInfo.estado_color) ? '#FFFFFF' : '#000000');

    //cambiar dinamicamente el color del fondo de la tarjeta
    useEffect(() => {
        setTextColor(isColorDark(estados.find(estado => estado.id == tareaInfo.id_estado).color) ? '#FFFFFF' : '#000000');
        setBackgroundCArd(estados.find(estado => estado.id == tareaInfo.id_estado).color);
    }, [tareaInfo.id_estado]);

    useEffect(() => {
        setTareaInfo(tarea);
    }, [tarea])

    const handleEvent = (opcionId) => {
        switch (opcionId) {
            case "1":
                handleOnChange({ id_estado: 1 })
                // Lógica para la opción 1
                break;
            case "2":
                handleOnChange({ id_estado: 2 })
                // Lógica para la opción 2
                break;
            case "3":
                handleOnChange({ id_estado: 3 })

                // Lógica para la opción 3
                break;
            case "4":
                handleOnChange({ id_estado: 4 })
                // Lógica para la opción 4
                break;
            case "50":
                setTareaEditar(true);
                break;
            case "51":
                eliminarTareaF(tarea.id);
                break;
            case "90":
                // Lógica para la opción 4
                break;
            default:
                console.log("Lógica para una opción desconocida");
                // Lógica para una opción desconocida
                break;
        }
    }


    const handleOnChange = (data) => {
        // Crear una copia del objeto tarea
        let temp = { ...tareaInfo };
        // Obtener la clave y el valor del objeto data
        const [key, value] = Object.entries(data)[0];

        // Verificar si la clave existe en el objeto tarea
        if (temp.hasOwnProperty(key)) {
            // Cambiar el valor del elemento que coincide
            temp[key] = value;
        }
        // Actualizar el estado de tarea con el nuevo objeto modificado
        // Suponiendo que estás usando useState para manejar el estado de tarea
        // setTareaInfo(temp); si se deja activo da la falsa ilusion de que laggea
        actualizarTareaEstado(temp);
        console.log(temp)
        // Para fines de depuración
    };

    const actualizarTareaEstado = (data) => {
        let temp = { ...data };
        temp.fechaActual = obtenerFechaHoraSQL();
        actualizarTarea(temp).then(() => {
            cargarTareas(); // Se ejecuta después de que actualizarTarea se completa
        })
    }

    const eliminarTareaF = async (id) => {
        try {
            const response = await eliminarTarea(id)
            if (response.success == true) {
                cargarTareas();
            }
        }
        catch (error) {
            console.error("Error al eliminar la tarea:", error);

        }
        console.log(id)
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            xs={12}
            // sm={6} md={4} xl={2}
            gap={1}
            fontWeight={500}
            padding={2}
            style={{
                backgroundColor: "#" + backgroundCard,
                color: textColor
            }}
            className="tarea-card fade-in2"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={11.5}
                >
                    <Typography variant="h4" gutterBottom>
                        Tarea: {tareaInfo.id}
                    </Typography>

                    <Grid item xs={1} onClick={() => setOpen(true)} className='pointer'>
                        <InfoIcon sx={{ fontSize: 30, marginBottom: 2 }} />
                    </Grid>

                    <Grid item xs={12} textAlign={'left'} >
                        <Typography variant="h5" gutterBottom
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 1, // Limita el número de líneas
                                WebkitBoxOrient: 'vertical',
                                maxWidth: '100%',
                                fontWeight: 400
                            }}
                        >
                            {tareaInfo.cliente}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'left', marginTop: '-0.9em' }}>
                        <Typography variant="caption" gutterBottom>
                            {tareaInfo.correo}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'left', marginTop: '-0.2em' }}>
                        <Typography variant="subtitle2" gutterBottom>
                            {tareaInfo.fecha}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'left', marginTop: '-0.5em' }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Horas empleadas: {tareaInfo.horas + ":" + tareaInfo.minutos + " HH:mm"}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            {/* <div className="separador"></div> */}

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
                xs={11.5}
            >
                <Typography variant="h5" gutterBottom style={{ fontWeight: 600, minHeight: '7vh' }} textAlign={'left'}>
                    {tareaInfo.asunto}
                </Typography>

                <Grid container xs={12} justifyContent="start" alignItems="start">
                    <Box
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 5, // Limita el número de líneas
                            WebkitBoxOrient: 'vertical',
                            maxWidth: '100%',
                            minHeight: '13vh', // Ajusta este valor a la altura fija que deseas
                            maxHeight: '13vh',
                        }}
                    >
                        <Typography variant="body1" gutterBottom textAlign={'left'}>
                            {tareaInfo.descripcion}
                        </Typography>
                    </Box>
                </Grid>


            </Grid>

            {/* <div className="separador"></div> */}


            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                xs={11.5}
                style={{
                    minHeight: '13.5vh',
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="start"
                    xs={5}
                >
                    {tareaInfo.trabajadores.length > 0 && (
                        <Grid container xs={12} direction="row" justifyContent="start" alignItems="start">
                            <Grid container xs={12} direction="row" justifyContent="start" alignItems="start" style={{ marginBottom: '-0.5em' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Asignado a:&nbsp;
                                </Typography>
                            </Grid>

                            {(tarea.trabajadores.slice(0, 3)).map((trabajador, index) => (
                                <Grid item xs={12} textAlign={'left'}>
                                    <Typography variant="caption" gutterBottom key={index} sx={{ fontWeight: 600 }}>
                                        {trabajador.label}&nbsp;
                                    </Typography>
                                </Grid>
                            ))}
                            <>
                                {tareaInfo.trabajadores.length > 3 && (
                                    <Typography variant="caption" gutterBottom sx={{ fontWeight: 600 }}>
                                        y mas...
                                    </Typography>
                                )}
                            </>
                        </Grid>
                    )}
                    {tareaInfo.trabajadores.length == 0 && (
                        <Grid container xs={12} justifyContent="start" alignItems="start"
                        // className='descripcion-titulo'
                        >
                            <Typography variant="subtitle1" gutterBottom>
                                Asignado a: &nbsp;
                            </Typography>
                            <Typography variant="caption" gutterBottom >
                                Sin Asignar
                            </Typography>
                        </Grid>
                    )}


                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="end"
                    alignItems="start"
                    xs={5}
                // style={{
                //     minHeight: '13vh',
                //     maxHeight: '13vh'
                // }}
                >
                    {tareaInfo.usuarios_con_tiempo.length > 0 && (
                        <Grid container xs={12} justifyContent="end" alignItems="start">
                            <Typography variant="subtitle1" gutterBottom style={{ marginBottom: '-0.2em' }} >
                                En curso por:&nbsp;
                            </Typography>
                            {(tarea.usuarios_con_tiempo.slice(0, 3)).map((trabajador, index) => (
                                <Grid container xs={12} justifyContent="end" alignItems="end" >
                                    <Typography variant="caption" gutterBottom key={index} sx={{ fontWeight: 600 }}>
                                        {trabajador.label}&nbsp;
                                    </Typography>
                                </Grid>

                            ))}
                            <>
                                {tareaInfo.usuarios_con_tiempo.length > 3 && (
                                    <Typography variant="caption" gutterBottom sx={{ fontWeight: 600 }}>
                                        y mas...
                                    </Typography>
                                )}
                            </>
                        </Grid>
                    )}
                    {tareaInfo.usuarios_con_tiempo.length == 0 && (
                        <Grid container xs={12} justifyContent="end" alignItems="end" >
                            <Typography variant="subtitle1" gutterBottom textAlign={'right'}>
                                En curso por:&nbsp;
                            </Typography>
                            <Typography variant="caption" gutterBottom textAlign={'right'}>
                                Sin Trabajadores En Curso
                            </Typography>
                        </Grid>
                    )}

                </Grid>

            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                xs={11.5}
                gap={1}
                style={{ marginTop: '0em' }}
            >
                {opciones.map((opcion, index) => (
                    <Grid container xs={3} sm={3} md={3} xl={3} key={index}>
                        <Button
                            fullWidth
                            variant='contained'
                            className='button-default'
                            key={index}
                            onClick={() => handleEvent(opcion.id_estado)}
                            disabled={
                                tareaInfo.id_estado == 4 ? true :
                                    opcion.id_estado == 4 ? tareaInfo.id_estado != 3 :
                                        tareaInfo.id_estado == 3 && opcion.id_estado != 4
                                            ? true
                                            : ((tareaInfo.userencurso))
                                                ? (opcion.id_estado == 1)
                                                : (opcion.id_estado == 2)

                            }
                        >
                            {opcion.nombre}
                        </Button>
                    </Grid>
                ))}
            </Grid>





            {/* //desplegable */}
            {
                tareaEditar == true && (
                    <FormEditarTarea open={tareaEditar} setOpen={setTareaEditar} cargarTareas={cargarTareas} tareaInfo={tareaInfo} setTareaInfo={setTareaInfo}
                        opclientes={opclientes} opTrabajadores={opTrabajadores} trabajadores={tarea.trabajadores} />
                )
            }
            {
                tareaEditar == true && (
                    <FormEditarTarea open={tareaEditar} setOpen={setTareaEditar} cargarTareas={cargarTareas} tareaInfo={tareaInfo} setTareaInfo={setTareaInfo}
                        opclientes={opclientes} opTrabajadores={opTrabajadores} trabajadores={tarea.trabajadores} />
                )
            }
            <Grid container xs={12} justifyContent={'space-between'}>
                <TareaDialog open={open} setOpen={setOpen} tareaInfo={tareaInfo} setTareaInfo={setTareaInfo} opciones={opciones} handleEvent={handleEvent} backgroundCard={backgroundCard} textColor={textColor} />
            </Grid >
        </Grid >


    )
}
