import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
export const ProyectoCard = (data) => {

    // Global
    const navigate = useNavigate();

    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3} >
                <Card
                    onClick={() => navigate("/proyecto/" + data.data.id)}
                    className="menu-card"
                    style={{ cursor: 'pointer', transition: '0.3s', borderRadius: 15, boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.4)',  background: "var(--fondoTarjeta)" }}
                >
                    <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                        <IconButton color='primary'>
                            <FolderSpecialIcon />

                        </IconButton>
                        <Typography variant="h6" component="div">
                            {data.data.nombre.toUpperCase()}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}
