import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { Header } from '../global/Header';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button, FormControl, Grid, InputLabel } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';


export const Login = ({ username, setUsername, password, setPassword }) => {
    const navigate = useNavigate();
    const wsUrl = "www.jcinformatics.com/tessamanagment";
    const [showPassword, setShowPassword] = useState(false);



    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post(`https://${wsUrl}/WS/login.php`, {
                mail: username,
                password: password,
            });
            if (response.data.success) {
                // Guarda el token en una cookie con una fecha de expiración
                const tokenExpiryDate = new Date();
                tokenExpiryDate.setTime(tokenExpiryDate.getTime() + (12 * 60 * 60 * 1000)); // 12 horas
                document.cookie = `PHPSESSID=${response.data.token}; path=/; expires=${tokenExpiryDate.toUTCString()};`;

                // Guarda otros datos en el almacenamiento local
                localStorage.setItem('username', response.data.nombre);
                localStorage.setItem('mail', response.data.mail);
                localStorage.setItem('DesRepresentante', response.data.nombre);

                setPassword('');
                setUsername('');

                Swal.fire({
                    icon: "success",
                    title: `Bienvenido/a \n${username}`,
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/');
            } else {
                Swal.fire({
                    icon: "error",
                    title: `Error de inicio de sesión: \n${response.data.message}`,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load fondo-verde'
            style={{ minHeight: '100vh' }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={11} sm={8} md={6} xl={3}
                    gap={2}
                    className='bordes-redondos fondo-blanco sombra-corta'

                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                    // style={{ marginBottom: '-2em' }}
                    >
                        <PersonIcon sx={{ fontSize: 80 }} style={{ marginBottom: '-0.3em' }} color='primary' />
                        <h1>Iniciar sesión</h1>
                        <small style={{ color: 'gray', marginBottom: '1.3em', marginTop: '-1.3rem' }}>Bienvenido/a a Tessa Management</small>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={11} sm={6} md={6} xl={8}
                            className='separador-2'
                        >
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                    // style={{ marginBottom: '-1em' }}

                    >
                        {/* <h2>Correo electronico</h2> */}
                        <TextField name='email' id="outlined-basic" label="Correo electronico" variant="outlined" color='primary' style={{ minWidth: '100%' }} onChange={(e) => setUsername(e.target.value)} onKeyPress={handleKeyPress} />
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                        style={{ marginBottom: '0.5em' }}

                    >
                        {/* <h2>Contraseña</h2> */}
                        <FormControl variant="outlined" style={{ minWidth: '100%' }} onKeyPress={handleKeyPress}>
                            <InputLabel htmlFor="outlined-adornment-password" color='primary' >Contraseña</InputLabel>
                            <OutlinedInput
                                name='password'
                                color='primary'
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl >
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                    // style={{ marginBottom: '1em' }}
                    >
                        <Button
                            variant="contained"
                            color='primary'
                            style={{ minWidth: '100%' }}
                            onClick={() => handleLogin()}
                        // disabled={username == '' && password == '' ? false : true}
                        >
                            Iniciar sesion&nbsp;<LoginIcon fontSize='small' />
                        </Button>
                    </Grid>
                    {/* <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                        style={{ marginBottom: '1em' }}
                    >
                        <Button variant="outlined" color='primary' style={{ minWidth: '100%' }} onClick={() => navigate("/registrarse")}>Registrarse&nbsp;<PersonAddAltIcon fontSize='small' /></Button>
                    </Grid> */}

                </Grid>

            </Grid>

            {/* <PiePagina></PiePagina> */}
        </Grid>

    )
};
