import * as React from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

const Root = styled('div')(
	({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
		};
  font-size: 16px;
`,
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  margin: 13px;
  display: block;
`;

const InputWrapper = styled('div')(
	({ theme }) => `
  width: 300px;
  border: 1px solid #adadad;
  background-color: var(--fondo);
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: var(--secundario);
  }

  &.focused {
    border: 2px solid #adadad;

     border-color: var(--principal);
  }

  & input {
    background-color: var(--fondo);
    color: black;
    height: 52px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props) {
	const { label, onDelete, ...other } = props;
	return (
		<div {...other}>
			<span>{label}</span>
			<CloseIcon onClick={onDelete} />
		</div>
	);
}

Tag.propTypes = {
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
	({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: var(--fondo2);
  border: 1px solid var(--principal);
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: var(--secundario);
    background-color:  var(--fondo2);
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 14px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
	({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
    background-color:  var(--fondo);
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color:  var(--principal);
    font-weight: 700;

    & svg {
      var(--secundario);
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color:  var(--principal);
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

export default function AutocompleteList({ array, setArray, arrayPre, label }) {
	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getTagProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
		value,
		focused,
		setAnchorEl,
	} = useAutocomplete({
		id: 'customized-hook-demo',
		defaultValue: arrayPre, // arrayPre se usa para preseleccionar
		multiple: true,
		options: array,
		getOptionLabel: (option) => option.label,
		isOptionEqualToValue: (option, value) => option.value === value.value, // Compara correctamente las opciones
	});

	React.useEffect(() => {
		setArray(value); // Actualizamos el estado cuando cambia la selección
	}, [value, setArray]);

	return (
		<Root>
			<div {...getRootProps()}>
				<Label {...getInputLabelProps()}>{label}</Label> 
				<InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
					{value.map((option, index) => {
						const { key, ...tagProps } = getTagProps({ index });
						return <StyledTag key={option.value} {...tagProps} label={option.label} />;
					})}
					<input {...getInputProps()} />
				</InputWrapper>
			</div>
			{groupedOptions.length > 0 ? (
				<Listbox {...getListboxProps()}>
					{groupedOptions.map((option, index) => {
						const { key, ...optionProps } = getOptionProps({ option, index });
						return (
							<li key={option.value} {...optionProps}>
								<span>{option.label}</span>
								<CheckIcon fontSize="small" />
							</li>
						);
					})}
				</Listbox>
			) : null}
		</Root>
	);
}
