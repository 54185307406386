import {
    Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, 
    InputBase, Paper, Snackbar, TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import TextsmsIcon from '@mui/icons-material/Textsms';
import SendIcon from '@mui/icons-material/Send';
import { postTarea } from '../../../services/apis';
import { useParams } from 'react-router-dom';
import AutocompleteList from '../../../utils/componentes-utiles/AutocompleteList';
import { AlertError } from '../../../utils/componentes-utiles/AlertError';

export const FormCrearTarea = ({ open, setOpen, tareaInfo, handleEvent, backgroundCard, textColor, opclientes, cargarTareas, opTrabajadores }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const param = useParams();
    const [errorAlert, setErrorAlert] = useState(false);
    const [clienteSelec, setClienteSelec] = useState({ label: "Seleccione un Cliente", value: "0" });
    const [trabajadorSelec, setTrabajadorSelec] = useState([]);
    const [tarea, setTarea] = useState({
        'fecha': new Date(),
        'asunto': '',
        'descripcion': '',
        'localizacion': '',
        'id_cliente': null,
        'minutos': '',
        'id_proyecto': param.id
    });

    const handleChange = (event) => {
        const tareaNueva = { ...tarea, [event.target.name]: event.target.value };
        setTarea(tareaNueva);
        setErrorAlert(false)
    };

    const isNullInObject = (obj) => {
        // Lista de campos que se deben omitir en la validación
        const camposExcepcion = ['localizacion', 'minutos', 'id_cliente', 'id_proyecto'];
        return Object.keys(obj).every(key => {
            if (camposExcepcion.includes(key)) {
                return true;
            }
            return obj[key] !== null && obj[key] !== '' && obj[key] != 0;
        });
    };

    const crearTarea = async () => {
        const nuevaTareaTmp = { ...tarea };
        nuevaTareaTmp.id_cliente = clienteSelec.value;
        nuevaTareaTmp.trabajadores = trabajadorSelec;

        const tareaComprobada = isNullInObject(nuevaTareaTmp);
        console.log(tareaComprobada);

        if (!tareaComprobada) {
            console.error("Hay campos vacíos o no válidos en la tarea");
            setErrorAlert(true);
            console.log(tarea);
            return; // Detener la ejecución si hay campos no válidos
        }

        try {
            const response = await postTarea(nuevaTareaTmp);
            if (response.success === true) {
                handleClose();

                console.log(nuevaTareaTmp);
                cargarTareas();
                setTarea({
                    'titulo': '',
                    'fecha': new Date(),
                    'asunto': '',
                    'descripcion': '',
                    'localizacion': '',
                    'id_cliente': null,
                    'minutos': '',
                    'id_proyecto': param.id
                });
                setClienteSelec({ label: "Seleccione un Cliente", value: "0" });
                setTrabajadorSelec([]);
                handleClose();

            }
        } catch (error) {
            console.error("Error al cargar tareas", error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        crearTarea();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                Crear Nueva Tarea
            </DialogTitle>

            <DialogContent style={{
                backgroundColor: `#${backgroundCard}`,
                color: textColor
            }}>
                <div className='flex-right'>
                    <CancelIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </div>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Autocomplete
                                className={opclientes ? "inputDateInput" : 'inputDateInput-empty'}
                                disablePortal
                                id="combo-box-demo"
                                options={opclientes}
                                sx={{ width: 300 }}
                                value={clienteSelec}
                                onChange={(event, newValue) => setClienteSelec(newValue)}
                                renderInput={(params) => <TextField {...params} label="Obligatorio*" />}
                            />
                        </Grid>
                        <Grid item>
                            <AutocompleteList array={opTrabajadores} setArray={setTrabajadorSelec} />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Asunto"
                                variant="outlined"
                                multiline
                                fullWidth
                                name="asunto"
                                value={tarea.asunto}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Descripción"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                name="descripcion"
                                value={tarea.descripcion}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions style={{
                backgroundColor: `#${backgroundCard}`,
                color: textColor
            }}>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Crear Tarea
                </Button>
            </DialogActions>
            {errorAlert && (
                <AlertError open={errorAlert} setOpen={setErrorAlert} titulo={"Error"} mensaje={"Falta rellenar algún campo obligatorio"} />
            )}
        </Dialog>
    );
};
