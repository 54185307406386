import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getProyecto } from '../../services/apis';

import CircularProgress from '@mui/material/CircularProgress';

import { ErrorYRedireccionar } from '../../utils/componentes-utiles/ErrorYRedireccionar';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';
import {  OpcionesMenuUrlId } from '../../utils/componentes-utiles/OpcionesMenuUrlId';


export const Proyecto = () => {
    // Global

    // Estados auxiliares
    const [loaded, setLoaded] = useState(false);

    // Estados útiles
    const [opciones, setOpciones] = useState([]);
    const [infoProyecto, setInfoProyecto] = useState({})
    const param = useParams();

    // Cargar opciones menú principal y la informacion
    const cargarProyecto = async () => {
        try {
            const response = await getProyecto(param.id);
            setOpciones(response.data.opciones);
            console.log(response.data)
            setInfoProyecto(response.data.info[0]);
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);

        }
    };

    useEffect(() => {
        cargarProyecto();
    }, []);
    return (
        <div className='menu-back fade-in-2'>
            {!loaded && (
                <CircularProgress />
            )}
            {loaded && (
                <>
                    <SubHeader />
                    <h1> {infoProyecto ? infoProyecto.nombre ? infoProyecto.nombre.toUpperCase() : '' : <ErrorYRedireccionar mensaje={'No tienes acceso a este Proyecto'} />}</h1>           
                    <OpcionesMenuUrlId opciones={opciones}></OpcionesMenuUrlId>
                </>
            )}
        </div>
    )
}
