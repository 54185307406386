import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

export const FichajeCard = ({ fichaje, index }) => {
    let dias = fichaje.dias;

    // Función para formatear los segundos a hh:mm:ss
    function formatTime(segundos) {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const seg = segundos % 60;
        return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(seg).padStart(2, '0')}`;
    }

    // Función para abrir Google Maps con las coordenadas
    const handleClick = (latitud, longitud) => {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url, '_blank');
    };

    return (
        <div className="flex-center">
            <Grid
                container
                xs={12}
                gap={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="fichaje-card fade-in2"
            >
                <Card
                    sx={{ width: '100%', padding: 2, borderRadius: 3, boxShadow: 3,  background: "var(--fondoTarjeta)"}}
                >
                    <CardContent>
                        <Typography variant="h5" component="div" gutterBottom>
                            {fichaje.usuario}
                        </Typography>
                        {dias.map((dia, index) => (
                            <div key={index} className="dia-container">
                                {Object.entries(dia).map(([fecha, fichajes]) => (
                                    <div key={fecha} className="fecha-container">
                                        <h4>{fecha}</h4>
                                        {fichajes.map((fichaje, i) => (
                                            <div key={i} className="fichaje-detalle">
                                                <p>Hora inicio: {fichaje.hora_inicio}</p>
                                                <p>Hora fin: {fichaje.hora_fin ? fichaje.hora_fin : 'En curso'}</p>
                                                <p>Total: {formatTime(parseInt(fichaje.total, 10))}</p>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleClick(fichaje.latitud_inicio, fichaje.longitud_inicio)}
                                                >
                                                    Ver en mapa
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};
