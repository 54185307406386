import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export const ErrorYRedireccionar = ({ mensaje }) => {
    const navigate = useNavigate();

    useEffect(() => {
        // Establecer un temporizador para redirigir después de 3 segundos
        const timer = setTimeout(() => {
            navigate('/');
        }, 5000);

        // Limpiar el temporizador si el componente se desmonta antes de que se cumpla el tiempo
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div>
            <h1>Error</h1>
            <p>{mensaje}</p>
            <p>Serás redirigido a la página principal en 5 segundos...</p>
        </div>
    );
};