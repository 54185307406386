import { Button, CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { getProyectos, opcinesMenu } from '../../services/apis';

import { ProyectoCard } from './ProyectoCard';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';

//mapa de iconos


export const Proyectos = () => {
    // Global
    const navigate = useNavigate();

    // Estados auxiliares
    const [loaded, setLoaded] = useState(false);

    // Estados útiles
    const [proyectos, setProyecto] = useState([]);

    // Cargar opciones menú principal
    const cargarOpciones = async () => {
        try {
            const response = await getProyectos();
            setProyecto(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);

        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <div className='menu-back fade-in2'>
            <SubHeader />
            <h1>PROYECTOS</h1>

            {
                !loaded && (
                    <CircularProgress />
                )
            }
            <div className='menu-principal fade-in-2'>
                <Grid xs={12} container spacing={3} justifyContent="center">
                    {loaded && proyectos.length > 0 && (
                        proyectos.map(opcion => (
                            <ProyectoCard key={opcion.id} data={opcion} />
                        ))
                    )}
                </Grid>
            </div>
        </div >
    )
}
