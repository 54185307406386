import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../cookies';
import { Button, Grid } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
export const CerrarSesion = () => {

    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');


    const cerrarSesion = async () => {

        // Elimina la cookie llamada 'phpssid'
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem('username');
        localStorage.removeItem('code');
        localStorage.removeItem('mail');
        localStorage.removeItem('DesRepresentante');


        Navigate("/login");

        try {
            const response = await axios.post('https://www.jcinformatics.com/tessamanagment/WS/Logout.php', {
                'token': token,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Respuesta del servidor:', response.data);
            if (response.data.succes) {
                console.log(response)
            }

        } catch (error) {
            console.error('Error al invalidar el token', error);
        }
    };
    return (
        <>
            {token && (

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => cerrarSesion()}
                >
                    <LogoutIcon fontSize="small" />
                    &nbsp;Cerrar Sesión&nbsp;
                </Button>

            )}
        </>
    );
}
