import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Grid, TextField, CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import { TareaCard } from './TareaCard';
import { getClientes, getEstados, getOpcionesTareas, getTareas, getTrabajadores } from '../../../services/apis';
import SquareIcon from '@mui/icons-material/Square';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import { useInterval } from 'react-use';

import { FormCrearTarea } from './FormCrearTarea';
import { ErrorYRedireccionar } from '../../../utils/componentes-utiles/ErrorYRedireccionar';
import { FormCrearSA } from './FormCrearSA';
import AutocompleteList from '../../../utils/componentes-utiles/AutocompleteList';
import { SubHeader } from '../../../utils/componentes-utiles/SubHeader';
import { OpcionesMenuFunc } from '../../../utils/componentes-utiles/OpcionesMenuFunc';

export const Tareas = () => {
    const iconMapping = {
        AddCircleIcon: <AddCircleIcon />
    };
    const Navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [tareas, setTareas] = useState([]);
    const [opciones, setOpciones] = useState([]);
    const [estados, setEstados] = useState([]);
    const [crearTarea, setCrearTarea] = useState(false);
    const [crearSA, setCrearSA] = useState(false);
    const [opcionesTareas, setOpcionesTareas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [opclientes, setOpClientes] = useState([]);
    const [opTrabajadores, setOpTrabajadores] = useState([]);
    const [nombreProyecto, setNombreProyecto] = useState([]);
    const [timer, setTimer] = useState(null)
    const [trabajadorSelec, setTrabajadorSelec] = useState({ label: "Aginar a un Trabajador", value: "0" });
    const [clientesSelec, setClientesSelec] = useState({ label: "Aginar a un Trabajador", value: "0" });

    //filtros
    const [tareasFiltradas, setTareasFiltradas] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    // Obtener la fecha actual y la fecha de hace 2 meses
    const today = new Date();
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(today.getMonth() - 6);

    // Formatear las fechas en formato 'YYYY-MM-DD' (ISO)
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    const [filtros, setFiltros] = useState(
        {
            fechaDesde: formatDate(twoMonthsAgo),  // Fecha de hace 2 meses
            fechaHasta: formatDate(today),         // Fecha actual

        }
    );
    const filtrosRef = useRef(filtros);

    //utils
    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), []);

    const param = useParams();
    const paramRef = useRef(param);


    useEffect(() => {
        const tempFIl = filtrarTareas(estados, tareas);
        console.log("estados", estados)
        setTareasFiltradas(tempFIl);
    }, [tareas]);


    useEffect(() => {
        primeraCarga();
        cargarOpcionesTareas();
        cargarClientes();
        cargarTrabajadores();
        cargarEstados();

    }, []);

    useEffect(() => {
        filtrosRef.current = filtros;
        cargarTareas();
    }, [filtros]);

    useEffect(() => {
        paramRef.current = param;
    }, [param]);

    useEffect(() => {
        setTareasFiltradas(filtrarTareas(estados, tareas));
    }, [trabajadorSelec, clientesSelec, searchTerm])

    const primeraCarga = async () => {
        let fechaDesde = filtros.fechaDesde;
        let fechaHasta = filtros.fechaHasta;
        try {
            const response = await getTareas(param.id, 1, fechaDesde, fechaHasta);
            setTareas(response.data.tareas);
            setTareasFiltradas(response.data.tareas);
            setOpciones(response.data.opcionesTareas);
            setNombreProyecto(response.data.nombreProyecto[0].nombre)
            setLoaded(true);
            document.title = `(${tareasFiltradas.length}) Tessa: ${response.data.nombreProyecto[0].nombre} Tareas`;
        

        } catch (error) {
            console.error("Error cargar tareas:", error);
            localStorage.clear();
            Navigate("/login");
        }

    }


    const cargarTareas = async () => {

        let fechaDesde = filtrosRef.current.fechaDesde;
        let fechaHasta = filtrosRef.current.fechaHasta;

        try {
            const response = await getTareas(param.id, 1, fechaDesde, fechaHasta);
            setTareas(response.data.tareas);
            document.title = `(${tareasFiltradas.length}) Tessa: ${response.data.nombreProyecto[0].nombre} Tareas`;

        } catch (error) {
            console.error("Error cargar tareas:", error);
            localStorage.clear();
            Navigate("/login");

        }

    }
    useInterval(cargarTareas, 30000);
    const cargarOpcionesTareas = async () => {
        try {
            const response = await getOpcionesTareas(param.id);
            setOpcionesTareas(response.data);
            console.log("sdad",response.data)
        } catch (error) {
            console.error("Error cargar tareas", error)
        }
    }


    const cargarEstados = async () => {
        try {
            const response = await getEstados();
            setEstados(response.data)

        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }
    const cargarClientes = async () => {
        try {
            const response = await getClientes();
            setClientes(response.data);
            const tmp = [...response.data];
            const newTmp = tmp.map((item, index) => ({
                label: item.nombre,
                value: item.id
            }))
            setOpClientes(newTmp);
        } catch (error) {
            console.error("Error cargar tareas", error)
        }
    }
    const cargarTrabajadores = async () => {
        try {
            const response = await getTrabajadores();
            const tmp = [...response.data];
            const newTmp = tmp.map((item, index) => ({
                label: item.nombre,
                value: item.id
            }))
            setOpTrabajadores(newTmp);
        } catch (error) {
            console.error("Error cargar tareas", error)
        }
    }

    const handleEvent = (opcionId) => {
        switch (opcionId) {
            case "1":
                setCrearTarea(true);

                // Lógica para la opción 1
                break;
            case "2":
                setCrearSA(true);
                break;

            default:
                console.log("Lógica para una opción desconocida ");

                // Lógica para una opción desconocida
                break;
        }
    }

    const handleChange = (event) => {

        const tareaNueva = { ...filtros, [event.target.name]: event.target.value };

        setFiltros(tareaNueva);
        if (event.target.name == ('fechaDesde' || 'fechaHasta')) {
            cargarEstados();
        }

    }

    const handleCheckboxChange = (id) => {
        const updatedEstados = estados.map((estado) =>
            estado.id === id ? { ...estado, checked: !estado.checked } : estado
        );

        setEstados(updatedEstados);
        setTareasFiltradas(filtrarTareas(updatedEstados, tareas));

    };


    //filtros Estados
    const filtrarTareas = (updatedEstados, tareas) => {
        // Filtrar por estados >>>>

        // Obtén los IDs de los estados marcados
        const checkedEstadoIds = updatedEstados
            .filter(estado => estado.checked)
            .map(estado => estado.id);

        // Filtra las tareas según los estados marcados
        const filteredTareas = tareas.filter(tarea =>
            checkedEstadoIds.includes(tarea.id_estado)
        );

        let tareasFiltradasPorTrabajadores = filteredTareas;
        // Filtrar las tareas por trabajadores seleccionados
        if (trabajadorSelec.length > 0) {
            tareasFiltradasPorTrabajadores = filteredTareas.filter(tarea =>
                Array.isArray(tarea.trabajadores) && tarea.trabajadores.some(trabajador =>
                    Array.isArray(trabajadorSelec) && trabajadorSelec.some(selTrab => selTrab.value === trabajador.value)
                )
            );
        }
        let tareasFiltradasPorClientes = tareasFiltradasPorTrabajadores;
        if (clientesSelec.length > 0) {
            // Filtrar las tareas por id_cliente que debe coincidir con el value de los objetos en clientesSelec
            tareasFiltradasPorClientes = tareasFiltradasPorTrabajadores.filter(tarea =>
                clientesSelec.some(selCliente => selCliente.value === tarea.id_cliente)
            )
        }

        let tareasFiltradasSearchTerm = tareasFiltradasPorClientes;

        //filtrar por texto
        if (searchTerm.length > 1) {
            const propiedadesParaBuscar = ['asunto', 'descripcion', 'fecha', 'cliente', 'correo', 'trabajadores'];

            tareasFiltradasSearchTerm = tareasFiltradasPorClientes.filter(tarea =>
                propiedadesParaBuscar.some(propiedad =>
                    tarea[propiedad] &&
                    typeof tarea[propiedad] === 'string' &&
                    tarea[propiedad].toLowerCase().includes(searchTerm.toLowerCase())
                )
            );

        }


        document.title = `(${tareasFiltradasSearchTerm.length}) Tessa: Tareas`; // Actualiza el título del documento

        return tareasFiltradasSearchTerm; // Devuelve las tareas filtradas
    };


    return (
        <div className='fade-in-2 '>

            <SubHeader />
            {!loaded && (
                <CircularProgress size={40} />
            )}
            {(loaded && estados.length > 0 &&  opcionesTareas) && (
                <>
                    <h1> {nombreProyecto}:  {tareasFiltradas.length} TAREAS </h1>
                    <OpcionesMenuFunc handleEvent={handleEvent} opciones={opcionesTareas}/>
                    <div className="flex-center">

                        <Grid container gap={2} maxWidth={"90%"} direction="row" justifyContent="center" alignItems="center"  >
                            {/* <h1>{tareas.length > 0 ? '' : <ErrorYRedireccionar mensaje={'No tienes acceso a este Proyecto'} />}</h1> */}

                            {estados.length > 0 && (
                                <> {/* filtros */}
                                    <Grid container xs={12} direction="row" justifyContent="center" alignItems="center" fontWeight={700} >
                                        <Grid container xs={12} sm={6} md={4} lg={2} xl={2} direction="column" justifyContent="center" alignItems="center" fontWeight={700} >
                                            <p>Fecha Desde</p>

                                            <TextField
                                                type="date"
                                                id="dateInput"
                                                name="fechaDesde"
                                                // className={filtros.fechaDesde ? "inputDateInput" : 'inputDateInput-empty'}
                                                required
                                                value={filtros.fechaDesde ? filtros.fechaDesde : new Date().toISOString().split('T')[0]} // Se establece la fecha de hoy si compra.Fecha es null
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid container xs={12} sm={6} md={4} lg={3} xl={2} direction="column" justifyContent="center" alignItems="center" fontWeight={700} >
                                            <p>Fecha Hasta</p>
                                            <TextField
                                                type="date"
                                                id="dateInput"
                                                name="fechaHasta"
                                                className={filtros.fechaHasta ? "inputDateInput" : 'inputDateInput-empty'}
                                                required
                                                value={filtros.fechaHasta ? filtros.fechaHasta : new Date().toISOString().split('T')[0]} // Se establece la fecha de hoy si compra.Fecha es null
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid container xs={12} md={6} lg={3} xl={2} direction="row" justifyContent="center" alignItems="center" fontWeight={700} >
                                            <Grid item>
                                                <AutocompleteList array={opTrabajadores} setArray={setTrabajadorSelec} label={"Asignados"}></AutocompleteList>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} md={6} lg={4} xl={3} direction="row" justifyContent="center" alignItems="center" fontWeight={700} >
                                            <Grid item>
                                                <AutocompleteList array={opclientes} setArray={setClientesSelec} label={"Clientes"}></AutocompleteList>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} md={12} lg={11} xl={3} direction="column" justifyContent="center" alignItems="center" fontWeight={700} spacing={1}>
                                            {/* Search Icon */}
                                            <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                                <SearchIcon sx={{ fontSize: 40 }} /> Buscar
                                            </Grid>
                                            <Grid container xs={12}>

                                                {/* TextField */}
                                                <TextField
                                                    className="textfieldBuscador"
                                                    onChange={debouncedHandleSearch}
                                                    label="Buscar"
                                                    fullWidth
                                                />
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                    {/* // leyentda estadoas */}


                                    <Grid container xs={12} direction="row" justifyContent="center" alignItems="center" fontWeight={700} >
                                        {estados.map(estado => (
                                            <Grid container xs={6} sm={4} md={3} xl={2} direction="row" justifyContent="center" alignItems="center" fontWeight={700} key={estado.id}>
                                                {estado.nombre}&nbsp;
                                                <SquareIcon style={{
                                                    color: "#" + estado.color,
                                                    backgroundColor: "#" + estado.color,
                                                    borderRadius: "4px",
                                                    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                                                }} />
                                                <Checkbox
                                                    checked={estado.checked == 1 ? true : false}
                                                    onChange={() => handleCheckboxChange(estado.id)}  // Cambia el estado al hacer clic
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Grid>

                                        ))}
                                    </Grid>
                                </>
                            )}
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="start"
                                xs={12}
                                gap={2}
                            >
                                {tareasFiltradas.map((tarea, index) => (
                                    <Grid container xs={12} sm={5} md={5} xl={2.3} direction="row" justifyContent="start" alignItems="start" fontWeight={700} key={index} >
                                        <TareaCard tarea={tarea} opciones={opciones} estados={estados} cargarTareas={cargarTareas} opclientes={opclientes} opTrabajadores={opTrabajadores} />
                                    </Grid>
                                ))}
                            </Grid>


                        </Grid>

                        <FormCrearTarea open={crearTarea} setOpen={setCrearTarea} opclientes={opclientes} opTrabajadores={opTrabajadores} cargarTareas={cargarTareas} />
                        <FormCrearSA open={crearSA} setOpen={setCrearSA} opclientes={opclientes} opTrabajadores={opTrabajadores} cargarTareas={cargarTareas} />

                    </div>
                </>
            )}
        </div >

    )
}
