
export const isColorDark = (color) => {
    // El color debe estar en formato hexadecimal (#rrggbb)
    let r, g, b;
    if (color.length === 6) {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
    } else {
        return false; // Devuelve falso si el color no es hexadecimal válido
    }

    // Calcula el brillo
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128; // Un brillo menor de 128 se considera oscuro
};


export const obtenerFechaHoraSQL = () => {
    const fechaActual = new Date();
  
    // Obtener los componentes de la fecha y hora
    const anio = fechaActual.getFullYear();
    const mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Sumar 1 al mes porque es cero-indexado
    const dia = String(fechaActual.getDate()).padStart(2, '0');
    const horas = String(fechaActual.getHours()).padStart(2, '0');
    const minutos = String(fechaActual.getMinutes()).padStart(2, '0');
    const segundos = String(fechaActual.getSeconds()).padStart(2, '0');
  
    // Formatear la cadena de fecha y hora para SQL DATETIME
    return `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
  };

  //generar color
export const generarColorAleatorio = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
  }

 